import { fmt } from "../components/utils/Fmt"
import { PassageVideo, PassageSegment, Passage, PassageNote } from "./ProjectModels"

import _debug from "debug"; let log = _debug('sltt:VisiblePassageVideo') 

/*
    Construct a new PassageVideo in ommitting any patches from the baseVideo
    which are newer than patchVideo.
 */

export class VisiblePassageVideo extends PassageVideo {
    constructor(passage: Passage, passageVideo: PassageVideo, noteCreationDate: string) {
        super(passageVideo._id)

        this.url = passageVideo.url

        // True if the video for this Id is not newer than creationData of patchVideo
        let notNewerThanNote = (videoId: string) => {
            let _video = passage.findVideo(videoId)
            if (!_video) return false

            /**
             * Using the creation date of the segment rather than the creation
             * date of the parent video BECAUSE for reasons I am not totally
             * clear on (but perhaps having to do with dragging and dropping
             * videos directly onto SLTT from the Telegram app) the patch videos
             * were in some previous cases getting weird dates causing them to
             * appear to be older than a note. Sigh.
             */
            let patchCreationDate = _video.segments[0].creationDate

            let notNewer = patchCreationDate <= noteCreationDate
            // log('notNewerThanNote', fmt({ 
            //     notNewer,
            //     videoId,
            //     _video,
            //     noteCreationDate,
            //     videoCreationDate: _video.creationDate,
            // }))

            return notNewer
        }

        // Create a copy of the segment omitting any videos newer than patchVideo
        let notNewerThanPatchSegment = function (_segment: PassageSegment) {
            let segment = new PassageSegment(_segment._id)

            segment.position = _segment.position
            segment.endPosition = _segment.endPosition
            segment.videoPatchHistory = _segment.videoPatchHistory.filter(notNewerThanNote)

            return segment
        }

        this.segments = passageVideo.segments.map(segment => notNewerThanPatchSegment(segment))

        this.setSegmentTimes(passage)

        log('!!!constructor', JSON.stringify(this.dbg(passage), null, 4))
    }

    async addViewedBy(email: string) {}

    async addNote(note: PassageNote): Promise<PassageNote> { return note }

}