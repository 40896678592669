import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { SortableContainer } from 'react-sortable-hoc'
import { t } from 'ttag'
import _debug from 'debug'
import { confirmAlert } from 'react-confirm-alert'

import PortionListItem from './PortionView'
import PortionAdder from './PortionAdder'
import './Portion.css'
import { systemError, ErrorBoundary } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { RootContext } from '../app/RootContext'
import { Portion, Project } from '../../models3/ProjectModels';
import LoadingMessage from '../utils/InitializationMessage'
import { InputLanguageSelector } from '../utils/InputLanguageSelector'
import { getByIso639dash1 } from '../utils/Languages'
import { PortionsListTRLContextComponent, getProjectTRLIssue } from '../utils/TRL'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { DownloadFullVideoIcon, EllipsisIcon, UnremoveIcon, UploadIcon } from '../utils/Icons'
import { appendPIE, csvToPIE, PIEPortion, pieToCsv, projectToPIE, validatePIE } from '../../models3/ProjectImportExport'
import { useFlags } from 'launchdarkly-react-client-sdk'

let log = _debug('sltt:PortionsEditor')

// Append portions and passages to project, return [error, result]
async function appendPIEFile(project: Project, file: File) {
    const text = (await file.text()).trim()

    let pie: PIEPortion[] = []

    if (file.name.toLowerCase().endsWith('.csv')) {
        const [_error, _pie] = csvToPIE(text)
        if (_error) return [_error, '']
        pie = _pie
    } else if (file.name.toLowerCase().endsWith('.json')) {
        pie = JSON.parse(text)
    } else {
        return [t`File extension must be .csv or .json` + ': ' + file.name, '']
    }

    const error = validatePIE(pie)
    if (error) return [error, '']
    
    const { skipped, added } = await appendPIE(project, pie)

    const result = t`Passages added:` + ' ' + added +
        '\n(' + t`Existing passages skipped:` + ' ' + skipped + ')'

    return ['', result] // success
}

type DownloadFormat = 'csv' | 'json'

function downloadPIE(project: Project, format: DownloadFormat) {
    const pie = projectToPIE(project)

    let data = ''
    if (format === 'csv') {
        data = pieToCsv(pie)
    } else  {
        data = JSON.stringify(pie, null, 4)
    }

    // Download resulting file
    const blob = new Blob([data], { type: 'text/plain' });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', `sltt.${format}`)
    link.click()
}


interface IUploadPIE {
    project: Project,
    setUploadPIE: (value: boolean) => void,
}

export const UploadPIE: FC<IUploadPIE> = observer(({ project, setUploadPIE }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [error, setError] = useState('')
    const [result, setResult] = useState('')
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handlePIEUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target.files
        if (!files || files.length !== 1) {
            setError('Please select a single file')
            return
        }

        const file = files[0]
        const [error, result] = await appendPIEFile(project, file)
        setError(error)
        setResult(result)

        // Clear file input so that selecting the same file will still cause onChange to fire
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    if (result) {
        return (
            <div className='portions-PIE-file-picker' ref={ref}>
                <div className='portions-upload-success'>
                    {result}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span className={`sl-fa-button far fa-times-circle portions-PIE-file-picker-cancel-button`}
                        onClick={() => setUploadPIE(false)} />
                </div>
            </div>
        )
    }

    return (
        <div className='portions-PIE-file-picker' ref={ref}>
            {error && 
                <div className='portions-upload-errors'>
                    {t`Fix the following errors and then chose the file again:` + '\n\n' + error}
                </div>}
            <div className='portions-PIE-file-picker-header'>
                <span>{t`Choose Portions/Passages to add to project` + ' (.csv,.json)'}</span>
            </div>
            <div>
                <input type="file" accept={"application/text"}
                    ref={fileInputRef}
                    onChange={handlePIEUpload} />
                <span className={`sl-fa-button far fa-times-circle portions-PIE-file-picker-cancel-button`}
                    onClick={() => setUploadPIE(false)} />
            </div>
        </div>
    )
})

interface IRareFunctionsDropdown {
    rt: Root,
}

const RareFunctionsDropdown: FC<IRareFunctionsDropdown> = ({ rt }) => {
    const { project, iAmAdmin } = rt
    const [uploadPIE, setUploadPIE] = useState(false)

    if (uploadPIE) {
        return <UploadPIE project={project} setUploadPIE={setUploadPIE} />
    }

    return (
        <Dropdown className='portions-editor-hamburger-menu' id='video-toolbar-hamburger-menu' pullRight>
            <Dropdown.Toggle className='portions-editor-hamburger-menu-toggle' noCaret>
                <EllipsisIcon className='main-video-see-more-icon' tooltip={t`See more options`} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <MenuItem className='portions-editor-hamburger-menu-item' 
                        onClick={() => downloadPIE(project, 'csv')} >
                    <>
                        <DownloadFullVideoIcon
                            className='sl-download-full-video-icon fa-fw'
                            tooltip=''
                        />
                        {t`Download Portions/Passages as CSV`}
                    </>
                </MenuItem>
                <MenuItem className='portions-editor-hamburger-menu-item' 
                        onClick={() => downloadPIE(project, 'json')} >
                    <>
                        <DownloadFullVideoIcon
                            className='sl-download-full-video-icon fa-fw'
                            tooltip=''
                        />
                        {t`Download Portions/Passages as JSON`}
                    </>
                </MenuItem>
                {iAmAdmin && <MenuItem className='portions-editor-hamburger-menu-item' 
                        onClick={() => setUploadPIE(true)}>
                    <>
                        <UploadIcon
                            className='sl-download-full-video-icon fa-fw'
                            tooltip=''
                        />
                        {t`Add Portions/Passages to project` + '...'}
                    </>
                </MenuItem>}
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface IRemovedObject {
    name: string,
    modBy: string,
    modDate: string,
}

// [James]: Deleted by bob@gmail.com. Deleted on 2021-09-01 08:00
export const unRemovedObjectTooltip = (rt: Root, removedObject: IRemovedObject) => {
    const { modBy, modDate,  name } = removedObject
    
    const _modDate = rt?.dateFormatter.format(new Date(modDate)) ?? '???'
    
    // modBy will be undefined on docs created before modBy was added
    return '[' + name + ']: ' +
        (modBy ? (t`Deleted by: ` + modBy + '. ') : '') +
        (modDate ? (t`Deleted on: ` + _modDate + '. ') : '')
}

export function confirmUnremove(
        rt: Root, 
        removedObject: IRemovedObject, 
        title: string, 
        doUnremove: () => void,
        className?: string, // supply this if you need to override the default css styling for dialog
    )
{
    const message = unRemovedObjectTooltip(rt, removedObject)

    confirmAlert({
        overlayClassName: className ?? "portion-confirm-unremove",
        title,
        message,
        // message: /* translator: important */ t`Are you sure you want to un-delete the portion and all its passages?`,
        buttons: [
            {
                label: (
                    <span className="portion-unremove-label">
                        <UnremoveIcon className="portion-unremove-icon" tooltip={''} />
                        {/* translator: important */ t`Yes`}
                    </span>),
                onClick: doUnremove,
            },
            {
                label: 'No',
                onClick: () => { },
            }
        ]
    })
}

interface IPortionUnremover {
    removedPortion: Portion,
}

const PortionUnremover: FC<IPortionUnremover> = observer(({ removedPortion }) => {
    const rt = useContext(RootContext)

    const clickTip = t`Click to un-delete this portion.`

    const title = /* translator: important */ t`Un-delete portion?`
    const tooltip = unRemovedObjectTooltip(rt!, removedPortion)

    async function doUnremove(portion: Portion) {
        await rt?.project.unremovePortion(portion)
        rt?.setPortion(portion) // select portion and scroll it into view
    }

    return (
        <div className="portions-list">
            {
                <div className="portion-deleted">
                    <div className="portion-name removed-portion-name"
                        onClick={() => 
                            confirmUnremove(rt!, removedPortion, title, 
                                () => doUnremove(removedPortion))}
                        data-toggle="tooltip"
                        title={tooltip + ' ' + clickTip}>
                            {removedPortion.name}
                        <UnremoveIcon className="portion-unremove-icon" tooltip={tooltip + ' ' + clickTip} />
                    </div>
                </div>
            }
        </div>
    )
})

interface IPortionsUnremover {
    rt: Root,
    removedPortions: Portion[],
}

const PortionsUnremover: FC<IPortionsUnremover> = observer(({removedPortions}) => {
    const rt = useContext(RootContext)
    
    if (removedPortions.length === 0) return null

    return (
        <div className="portions-list">
            <hr className="portions-hr" />
            {
                removedPortions.filter(p=>p.name).map((portion, index) => (
                    <PortionUnremover 
                        removedPortion={portion} 
                        key={index} />
                ))
            }
            <hr />
        </div>
    )
})

interface ISortableContainer {
    rt: Root,
    portions: Portion[],
}

const SortableList = SortableContainer( (props: ISortableContainer) => {
    return (
        <div className="portions-list">
            { 
                props.portions.map((portion, index) => (
                    <ErrorBoundary
                        key={portion._id}
                        fallbackUI={(
                            <div
                                className='portion-portion portion-error'
                                title={t`Something went wrong while displaying this portion`}
                            >
                                <b>{`${portion.name}???`}</b>
                            </div>
                        )}
                    >
                        <PortionListItem 
                            disabled={!props.rt.iAmInterpreter}
                            index={index} 
                            rt={props.rt}
                            portion={portion} />
                    </ErrorBoundary>
                ))
            }
        </div>
    )
})

interface IPortionsEditor {
    rt: Root,
}

const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    let { project, initialized, loadingMessage, useMobileLayout, isTRLProject } = rt
    const { inputLanguagePrimary } = { ...project } 
    const [titleLanguageCode, setTitleLanguageCode] = useState('')
    useEffect(() => {
        // inputLanguagePrimary may not have a value until after component is loaded
        const primaryInputLanguage = project.getPrimaryInputLanguageOrDefault()
        if (titleLanguageCode !== primaryInputLanguage) {
            setTitleLanguageCode(primaryInputLanguage)
        }
    }, [inputLanguagePrimary])
    
    useEffect(() => {
        // refresh the status for which portions have been published
        rt.loadTRLResources().catch(console.error)
    }, [])
    const flags = useFlags()

    const projectTrlIssue = getProjectTRLIssue(rt)
    const portionsEditorRef = useRef()

    if (!project) return null

    let { portions, removedPortions } = project

    /* eslint-disable no-unused-expressions */
    portions.length // necessary to force re-render, dont know why
    
    let projectName = project.displayName

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    const chooseTitleLanguage = t`Choose Title Language`;
    return (
        <div ref={portionsEditorRef as any} className='portions-editor'>
            <div className='portions-editor-header'>
                <h3>{/* translator: important */ t`Portions (${projectName})`}</h3>
                <div className='portions-editor-right-side'>
                    {isTRLProject && <div className="title-input-language-selector">
                        <i className="fas fa-fw fa-font" title={chooseTitleLanguage}></i>
                        <InputLanguageSelector
                            primaryInputLanguage={project.getPrimaryInputLanguageOrDefault()}
                            currentValue={titleLanguageCode}
                            selectorTooltip={chooseTitleLanguage}
                            getOptionTooltip={getTooltip}
                            chooseOption={setTitleLanguageCode}
                            disabled={false}
                        />
                    </div>}
                    {flags.importExportProjectStructure && <RareFunctionsDropdown rt={rt} />}
                </div>
            </div>
            <hr/>
            <PortionsListTRLContextComponent
                titleLanguage={titleLanguageCode}
                isTRLProject={isTRLProject}
                projectTrlIssue={projectTrlIssue}
                portionsEditorRef={portionsEditorRef}
            >
                <SortableList 
                    portions={portions}
                    rt={rt}
                    useDragHandle={true}
                    onSortEnd={onSortEnd} />
            </PortionsListTRLContextComponent>
            {!useMobileLayout && <PortionAdder rt={rt} />}
            {rt.iAmAdmin && <PortionsUnremover rt={rt} removedPortions={removedPortions} />}
        </div>
    )

    function onSortEnd(result: any) {
        let oldIndex: number = result.oldIndex
        let newIndex: number = result.newIndex
        
        let { project } = rt

        project.movePortion(project.portions[oldIndex]._id, newIndex)
            .catch(systemError)
    }
})

function getTooltip(
    targetCode: string,
    primaryInputLanguage: string,
) {
    const primaryInputLanguageName = getByIso639dash1(primaryInputLanguage).local
    return (targetCode !== primaryInputLanguage) ?
        t`This language is not the primary input language (${primaryInputLanguageName})` : ''
}

export default PortionsEditor