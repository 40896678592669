import React, { Component, FC, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import _debug from 'debug'
import { displayError } from '../utils/Errors'
import Modal from 'react-bootstrap/lib/Modal'

import { Root } from '../../models3/Root'
import { Passage, PassageContentType } from '../../models3/ProjectModels'
import { CancelEditSegmentButton, OkEditSegmentButton, SegmentReferencesButton } from '../utils/Buttons'
import { ReferenceInput } from '../utils/ReferenceInput'
import { s } from '../utils/Fmt'
import { CogIcon, HashtagIcon, ReferencesIcon } from '../utils/Icons'

// change progress to use passage.references
// Permissions: difficulty (admin/consultant)
// add camera icon for passage name

let log = _debug('sltt:PassageEditor') 

function validateName(rt: Root, passage: Passage, name: string) {
    if (passage.name === name) return ''
    return rt.portion!.checkNewPassageName(name)
}

interface IPassageNameInput {
    rt: Root,
    passage: Passage,
    passageName: string,
    setPassageName: (name: string) => void,
    nameError: string,
    setNameError: (name: string) => void,
    onEnter?: () => void,
}

const PassageNameInput: FC<IPassageNameInput> = ({ rt, passage, passageName, setPassageName, nameError, setNameError, onEnter }) => {
    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        let name = e.target.value
        setPassageName(name)
        setNameError(validateName(rt, passage, name))
    }

    return (
        <div className="passage-name-input">
            <div>
                <input
                    autoFocus
                    value={passageName}
                    onKeyDown={e => (e.keyCode === 13) && onEnter && onEnter()}
                    onChange={onChange} />
            </div>
            <div className="passage-name-input-error">
            {nameError && <span>{nameError}</span>}
            </div>
        </div>
    )
}

interface IPassagContentTypeInput {
    contentType: PassageContentType,
    setContentType: (contentType: PassageContentType) => void,
}

const PassageContentTypeInput: FC<IPassagContentTypeInput> = ({ contentType, setContentType }) => {
    return (
        <select value={contentType} onChange={e => setContentType(e.target.value as PassageContentType)} >
            <option value='Introduction' > {t`Introduction`} </option>
            <option value='Translation' > {t`Translation`} </option>
            <option value='Glossary' > {t`Glossary Entry`} </option>
            <option value='Other' > {t`Other`} </option>
            <option value='Nonpublishable' > {t`Nonpublishable`} </option>
            <option value='Introduction+Translation' > {t`Introduction + Translation`} </option>
            <option value='Introduction+Translation+Other' > {t`Introduction + Translation + Other`} </option>
        </select>
    )
}

interface IDifficultyInput {
    difficulty: number,
    setDifficulty: (difficulty: number) => void,
}

const DifficultyInput: FC<IDifficultyInput> = ({ difficulty, setDifficulty }) => {
    return (
        <div>
            <CogIcon className="passage-difficulty-icon" tooltip={t`Difficulty for passage.`} />
            <input
                className='difficulty-input'
                required
                type='number'
                value={difficulty}
                min={0}
                step={.1}
                onChange={e => setDifficulty(parseFloat(e.target.value))}
            />
        </div>
    )
}

interface IHashtagsInput {
    hashtags: string,
    setHashtags: (hashtags: string) => void,
}

const HashtagsInput: FC<IHashtagsInput> = ({ hashtags, setHashtags }) => {
    return (
        <div className="passage-hashtags-div">
            <HashtagIcon className="passage-hashtags-icon" tooltip={t`Hashtags for passage.`} />
            <input
                className="passage-hashtags-input"
                type='text'
                value={hashtags}
                onChange={e => setHashtags(e.target.value)}
            />
        </div>
    )
}

interface IPassageEditor {
    rt: Root,
    passage: Passage,
    editing: boolean,
    setEditing: (editing: boolean) => void
}

const PassageEditor: FC<IPassageEditor> = ({ rt, passage, editing, setEditing }) => {
    let [passageName, setPassageName] = useState(passage.name)
    let [nameError, setNameError] = useState('')
    let [references, setReferences] = useState(passage.references)
    let [referencesError, setReferencesError] = useState(false)
    let [difficulty, setDifficulty] = useState(passage.difficulty)
    let [contentType, setContentType] = useState(passage.contentType)
    let [hashtags, setHashtags] = useState(passage.hashtags)

    const { iAmInterpreter } = rt

    async function save() {
        if (!iAmInterpreter) return

        if (passage.name !== passageName && !nameError) {
            await passage.setName(passageName)
                .catch(displayError)
        }
        if (!referencesError) {
            await passage.setReferences(references).catch(displayError)
            // (EB) We need to update references in the Root since it has a copy
            // of the references that only gets set when switching passages.
            rt.setDbsRefs(rt.portion, null)
        }
        await passage.setDifficulty(difficulty).catch(displayError)
        await passage.setContentType(contentType).catch(displayError)
        await passage.setHashtags(hashtags).catch(displayError)

        setEditing(false)
    }

    return (
        <Modal style={{ top: '1%' }}
            bsSize="large"
            show={editing}
            onHide={() => setEditing(false)} >
            <Modal.Header closeButton> {t`Passage Information`} </Modal.Header>
            <Modal.Body className="passage-editor">
                <div className="">
                    <PassageNameInput {...{rt, passage, passageName, setPassageName, nameError, setNameError, onEnter: save}} />
                    <PassageContentTypeInput {...{ contentType, setContentType}} />
                </div>
                <hr/>
                <div className="passage-reference-input">
                    <ReferencesIcon 
                        className="passage-references-icon"
                        tooltip={t`References for passage.`} />
                    <ReferenceInput
                        refInput={rt}
                        onEnter={save}
                        refs={references}
                        setRefs={setReferences}
                        errored={referencesError}
                        setErrored={setReferencesError}
                        includeGotoReferenceButton={false} />
                </div>
                <div>
                    <HashtagsInput {...{ hashtags, setHashtags }} />
                </div>
                <div>
                    <DifficultyInput {...{ difficulty, setDifficulty }} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <OkEditSegmentButton
                    enabled={iAmInterpreter && !nameError && !referencesError}
                    onClick={save} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={() => setEditing(false)} />
            </Modal.Footer>
        </Modal>
    )
}

export default observer(PassageEditor)